import request from '@/api/utils';

export function getAlarmList(formdata = {}, method = 'post') {
  const url = '/api/alarm/list';
  return request.Api(url, formdata, method);
}
export function getAlarmRuleList(formdata = {}, method = 'post') {
  const url = '/api/alarm/rule/list';
  return request.Api(url, formdata, method);
}
export function setAlarm(formdata = {}, method = 'post') {
  const url = '/api/alarm/update';
  return request.Api(url, formdata, method);
}


