import request from '@/api/utils';

export function getCompanyFarmList(formdata = {}, method = 'post') {
  const url = '/api/farm/farmlist';
  return request.Api(url, formdata, method);
}
export function getFarmList(formdata = {}, method = 'post') {
  const url = '/api/farm/list';
  return request.Api(url, formdata, method);
}

export function getFarm(formdata = {}, method = 'post') {
  const url = '/api/farm/info';
  return request.Api(url, formdata, method);
}

export function setFarmCreate(formdata = {}, method = 'post') {
  const url = '/api/farm/create';
  return request.Api(url, formdata, method);
}

export function setFarmEdit(formdata = {}, method = 'post') {
  const url = '/api/farm/edit';
  return request.Api(url, formdata, method);
}

export function setFarmDel(formdata = {}, method = 'post') {
  const url = '/api/farm/del';
  return request.Api(url, formdata, method);
}

export function getSantralList(formdata = {}, method = 'post') {
  const url = '/api/farm/santralList';
  return request.Api(url, formdata, method);
}

export function getDeviceList(formdata = {}, method = 'post') {
  const url = '/api/farm/deviceList';
  return request.Api(url, formdata, method);
}
export function getDataloggerList(formdata = {}, method = 'post') {
  const url = '/api/farm/getDataloggerList';
  return request.Api(url, formdata, method);
}

export function newDatalogger(formdata = {}, method = 'post') {
  const url = '/api/farm/newDatalogger';
  return request.Api(url, formdata, method);
}

export function getFarmDataMinute(formdata = {}, method = 'post') {
  const url = '/api/farmdata/minute';
  return request.Api(url, formdata, method);
}

export function getSantrallerDaily(formdata = {}, method = 'post') {
  const url = '/api/santraller/dailypower';
  return request.Api(url, formdata, method);
}

export function getSantrallerMonthly(formdata = {}, method = 'post') {
  const url = '/api/santraller/monthlypower';
  return request.Api(url, formdata, method);
}

export function getSantrallerYearly(formdata = {}, method = 'post') {
  const url = '/api/santraller/yearlypower';
  return request.Api(url, formdata, method);
}
export function getSantrallerTotal(formdata = {}, method = 'post') {
  const url = '/api/santraller/totalpower';
  return request.Api(url, formdata, method);
}
