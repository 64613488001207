<template>
  <div class="v-application--wrap">
    <the-layout-drawer :permission-routes="stationRoute" />
    <the-layout-toolbar />
    <the-layout-content />
    <the-layout-fab />
    <the-layout-to-top-fab />
    <!--  <the-layout-footer /> -->
  </div>
</template>

<script>
import stationRoute from "@/router/modules/stations";
import TheLayoutDrawer from "../TheLayoutDrawer.vue";
import TheLayoutToolbar from "../TheLayoutToolbar.vue";
import TheLayoutFab from "../TheLayoutFab.vue";
import TheLayoutToTopFab from "../TheLayoutToTopFab.vue";
// import TheLayoutFooter from '../TheLayoutFooter.vue';
import TheLayoutContent from "../TheLayoutContent.vue";

export default {
  name: "TheLayout",
  components: {
    TheLayoutDrawer,
    TheLayoutToolbar,
    TheLayoutContent,
    TheLayoutFab,
    TheLayoutToTopFab,
    // TheLayoutFooter,
  },
  data: () => ({ stationRoute }),
};
</script>
