import Vue from 'vue';
import Vuex from 'vuex';
// eslint-disable-next-line import/no-cycle
import syncStorage from './plugins/syncStorage';
import permission from './modules/permission';
import settings from './modules/settings';
// eslint-disable-next-line import/no-cycle
import user from './modules/user';
// eslint-disable-next-line import/no-cycle
import cube from './modules/cube';
// eslint-disable-next-line import/no-cycle
import saleData from './modules/dashlist';
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import rawData from './modules/rawdata';
// eslint-disable-next-line import/no-cycle
import common from './modules/common';
import sensor from './modules/sensor';
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import serviceSettings from './modules/serviceSettings';
// eslint-disable-next-line import/no-cycle
import googleMaps from './modules/googleMaps';
import santral from './modules/santral';
import farm from './modules/farm';
import alarm from './modules/alarm';
import osos from './modules/osos';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    permission,
    settings,
    user,
    cube,
    saleData,
    rawData,
    sensor,
    common,
    serviceSettings,
    googleMaps,
    santral,
    farm,
    alarm,
    osos,
  },

  plugins: [
    syncStorage({}),
  ],

  state: {},
  mutations: {},
  actions: {},
  getters: {},
});
