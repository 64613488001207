import route from './route';
import button from './button';
import modal from './modal';
import global from './global';
import header from './header';
import osos from './osos';

export default {
  route,
  button,
  modal,
  global,
  header,
  osos,
  general: {
    date: 'Tarih',
    updateTime: 'Güncelleme Tarihi',
    error: 'Hata',
    warning: 'Uyarı',
    connection: 'Bağlantı',
  },
  daily: {
    Sunday: 'Pazar',
    Monday: 'Pazartesi',
    Tuesday: 'Salı',
    Wednesday: 'Çarşamba',
    Thursday: 'Perşembe',
    Friday: 'Cuma',
    Saturday: 'Cumartesi',

  },
  date: {
    Jan: 'Oca',
    Feb: 'Şub',
    Mar: 'Mar',
    Apr: 'Nis',
    May: 'May',
    Jun: 'Haz',
    Jul: 'Tem',
    Aug: 'Ağu',
    Sep: 'Eyl',
    Oct: 'Eki',
    Nov: 'Kas',
    Dec: 'Ara',
    Sun: 'Pz',
    Mon: 'Pzt',
    Tue: 'Sal',
    Wed: 'Çar',
    Thu: 'Per',
    Fri: 'Cum',
    Sat: 'Cts',
  },
  DateRange:"Tarih Aralığı",
  DailyEnergy: 'Günlük Enerji',
  DailyEnergys: 'Günlük Enerji',
  IstantaneousPower: 'Anlık Güç',
  PerformanceRatio: 'Performans Oranı',
  PerformanceRatioEast: 'Dogu Performans Oranı',
  PerformanceRatioWest: 'Batı Performans Oranı',
  PerformanceRatioSouth: 'Güney Performans Oranı',
  TheoreticalPower: 'Teorik Güç',
  TheoreticalPowerEast: 'Dogu Teorik Güç',
  TheoreticalPowerWest: 'Batı Teorik Güç',
  TheoreticalPowerSouth: 'Güney Teorik Güç',
  TotalEnergy: 'Toplam Enerji',
  Irradiation: 'Işınım',
  IrradiationEast: 'Dogu Işınım',
  IrradiationWest: 'Batı Işınım',
  IrradiationSouth: 'Güney Işınım',
  TempSouth: 'Güney Sıcaklık',
  TempWest: 'Batı Sıcaklık',
  TempEast: 'Dogu Sıcaklık',
  IrradiationCalculated: 'Işınım',
  Irradiation: 'Işınım',
  Irr: 'Işınım',
  Temperature: 'Sıcaklık',
  Temp: 'Sıcaklık',
  PanelTemp1: 'Sıcaklık',
  PanelTemp1: 'Sıcaklık',
  NominalPlantPower: 'Kurulu Güç',
  ACPower: 'AC Güç',
  Price: 'Kazanç',
  WeightedAveragePerformance: 'Ağırlıklı Ortalama Performans',
  EquivalentTrees: 'Eşdeğer Ağaçlar',
  BarrelsofOil: 'Petrol Varil',
  CO2Avoided: 'CO2 Azaltma',
  company_name: 'Şirket',
  MPPT1PanelVoltageProductivity: 'MPPT1 panel voltajı verimliliği',
  MPPT2PanelVoltageProductivity: 'MPPT2 panel voltajı verimliliği',
  MPPT3PanelVoltageProductivity: 'MPPT3 panel voltajı verimliliği',
  MPPT4PanelVoltageProductivity: 'MPPT4 panel voltajı verimliliği',
  MPPT5PanelVoltageProductivity: 'MPPT5 panel voltajı verimliliği',
  MPPT6PanelVoltageProductivity: 'MPPT6 panel voltajı verimliliği',
  MPPT7PanelVoltageProductivity: 'MPPT7 panel voltajı verimliliği',
  MPPT8PanelVoltageProductivity: 'MPPT8 panel voltajı verimliliği',
  MPPT9PanelVoltageProductivity: 'MPPT9 panel voltajı verimliliği',
  MPPT10PanelVoltageProductivity: 'MPPT10 panel voltajı verimliliği',

  toolbar: {
    appname: 'smartydata',
    settings: 'Ayarlar',
    profile: 'Profil',
    logout: 'Çıkış',
  },
  settings: {
    title: 'Tema Ayarları',
    default: 'Default ayarlara dön',
    position: 'Element pozisyonları',
    theme: 'Tema',
    dark: 'Karanlık tema',
    toolbarDense: 'Toolbar dense',
    navbarDense: 'Navbar dense',
    navbarLogo: 'Navbar logo',
    settingsBtn: 'Setting btn',
    footer: 'Footer',
  },
  login: {
    titleIn: 'Giriş Yap Form',
    titleUn: 'Kayıt Ol Form',
    singupForm: 'Kayıt Formu',
    singIn: 'Giriş Yap',
    singUp: 'Kayıt Ol',
    email: 'Email',
    password: 'Şifre',
    confirm: 'Doğrulama',
    passwordconfirm: 'Doğrulama',
    company_name: 'Şirket Adı',
    firstname: 'İsim',
    lastname: 'Soyisim',
  },
  errors: {
    whoops: 'Whoops',
    back: 'Buradan Ayrıl!',
    home: 'Anasayfaya git',
    301: 'Geçici olarak taşınmış',
    401: 'Yetkilendirme yok',
    403: 'Yasaklanmış',
    404: 'Sayfa Bulunamadı',
    500: 'Dahili Sunucu Hatası',
  },
  guide: {
    description: 'The guide page is useful for some people who entered the project for '
      + 'the first time. You can briefly introduce the features of the project. Demo is based on',
    button: 'Show Guide',
  },
  ui: {
    switch: 'Switch Language',
    theme: 'Switch Theme',
    success: 'Success',
    error: 'Error',
    warning: 'Warning',
    info: 'Info',
    primary: 'Primary',
    secondary: 'Secondary',
    default: 'Default',
    accent: 'Accent',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'E-mail',
  },
  alarm: {
    inverters_acpower: "İnverterlerin AC güçleri",
    inverter_stringbox_string: "İnverter String'lerinin string akımları",
    string_dccurrent: "Stringlerin akımları",
    string_total_dccurrent: "Stringlerin toplam akımları",
    
    'InverterErrorWarning': "İnverter hata ve uyarıları",
    'DeviceAccess': "inverter ya da stringbox durduğunda uyarı tetiklenecek.	",
    'ProductionandCommunication': "inverter ya da stringbox üretim durduğunda uyarı tetiklenecek.	",
    "DataLoggerAccess": "Veri kaydedicilerden gelmesi gereken verileri izleyen alarm sistemi. Raporlama döngüsüne göre girilen gecikme periyodu kadar veri gelmemesi durumunda alarm oluşur.",
    "UserDefinedAlarms": "Sistemde önceden tanıtılmamış, kişisel isteklerinize uyarlanabilecek alarm çeşidi. Veri erişiminin olduğu her modüle ait okunan her değerin belirli koşullara, eşik değerlerine ve sürelere göre ayarlanabileceği alarm çeşidi."

  }
};
