const rawdata = {
  state: {

  },
  getters: {

  },
  mutations: {

  },
  actions: {

  },
};

export default rawdata;
