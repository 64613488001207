import request from '@/api/utils';

export function getInverterDataList(formdata = {}, method = 'post') {
  const url = '/api/inverter/list';
  return request.Api(url, formdata, method);
}

export function newInverterCreate(formdata = {}, method = 'post') {
  const url = '/api/inverter/create';
  return request.Api(url, formdata, method);
}

export function inverterHourly(formdata = {}, method = 'post') {
  const url = '/api/inverter/hourcomparison';
  return request.Api(url, formdata, method);
}

export function inverterMinute(formdata = {}, method = 'post') {
  const url = '/api/inverter/minutecomparison';
  return request.Api(url, formdata, method);
}

export function inverterDaily(formdata = {}, method = 'post') {
  const url = '/api/inverter/daily';
  return request.Api(url, formdata, method);
}

export function inverterMonthly(formdata = {}, method = 'post') {
  const url = '/api/inverter/monthly';
  return request.Api(url, formdata, method);
}

export function inverterYearly(formdata = {}, method = 'post') {
  const url = '/api/inverter/yearly';
  return request.Api(url, formdata, method);
}

export function setInverterDelete(formdata = {}, method = 'post') {
  const url = '/api/inverter/setInverterDelete';
  return request.Api(url, formdata, method);
}

export function getInverterList(formdata = {}, method = 'post') {
  const url = '/api/inverter/getInverterList';
  return request.Api(url, formdata, method);
}

export function getInverterTypeList(formdata = {}, method = 'post') {
  const url = '/api/inverter/getInverterTypeList';
  return request.Api(url, formdata, method);
}

export function setInverter(formdata = {}, method = 'post') {
  const url = '/api/inverter/setInverter';
  return request.Api(url, formdata, method);
}

export function getInverterStatus(formdata = {}, method = 'post') {
  const url = '/api/inverter/status';
  return request.Api(url, formdata, method);
}

export function getTimeofinverter(formdata = {}, method = 'post') {
  const url = '/api/inverter/timeofinverter';
  return request.Api(url, formdata, method);
}

export function getInverterModel(formdata = {}, method = 'post') {
  const url = '/api/inverter/model';
  return request.Api(url, formdata, method);
}

export function getInverterErrorList(formdata = {}, method = 'post') {
  const url = '/api/inverter/errorlist';
  return request.Api(url, formdata, method);
}
