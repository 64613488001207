import request from '@/api/utils';

export function setCompanyCreate(formdata = {}, method = 'post') {
  const url = '/api/company/create';
  return request.Api(url, formdata, method);
}

export function setCompanyEdit(formdata = {}, method = 'post') {
  const url = '/api/company/edit';
  return request.Api(url, formdata, method);
}

export function getCompanyList(formdata = {}, method = 'post') {
  const url = '/api/company/list';
  return request.Api(url, formdata, method);
}

export function getCompany(formdata = {}, method = 'post') {
  const url = '/api/company/getCompany';
  return request.Api(url, formdata, method);
}
export function getCompanyDel(formdata = {}, method = 'post') {
  const url = '/api/company/delete';
  return request.Api(url, formdata, method);
}
