import Vue from 'vue';
import Router from 'vue-router';
import authRouter from './modules/auth';
import detailRoute from './modules/detail';
import settingRoute from './modules/setting';
import stationRoute from './modules/stations';

Vue.use(Router);

export const asyncRoutes = [
  ...authRouter,
  {
    path: '*',
    redirect: '/error/404',
    hidden: true,
  },
  {
    path: '/error/404',
    name: 'ErrorPage',
    component: () => import('@/views/page/ErrorPage.vue'),
  },
];

export default new Router({
  scrollBehavior: () => ({
    y: 0,
  }),
  routes: [...asyncRoutes, ...detailRoute, ...settingRoute, ...stationRoute],
  mode: 'history',
});
