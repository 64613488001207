import NProgress from 'nprogress'; // progress bar
import router from './router';
import store from './store';
import authRouter from './router/modules/auth';
import 'nprogress/nprogress.css'; // progress bar style

NProgress.configure({
  showSpinner: false,
}); // NProgress Configuration

const whiteList = (['/landing', '/land', '/home']
    .concat(Array.from(authRouter, (route) => route.path))
    .concat(Array.from(authRouter, (route) => route.alias)))
  .filter((route) => route); // remove undefined element

// permission judge function
function hasPermission(roles, permissionRoles) {
  if (roles.includes('admin')) return true; // admin permission passed directly
  if (!permissionRoles) return true;
  return roles.some((role) => permissionRoles.includes(role));
}

router.beforeEach(async (to, from, next) => {
  NProgress.start();

  try {
    if (store.getters.token) {
 
      if (whiteList.includes(to.path)) {
        next({
          path: '/',
        });
      } else if (!store.getters.roles || store.getters.roles.length === 0) {
        const data = await store.dispatch('GetUserInfo');

        if (!data || !data.user || !data.user.roles) {
          await store.dispatch('LogOut');
          next({
            path: '/',
          });
        }

        const {
          roles,
        } = data.user;

        const accessedRoutes = await store.dispatch('GenerateRoutes', {
          roles,
        });

        if (!accessedRoutes) {

          next({
            path: '/',
          });
        }

        router.addRoutes(accessedRoutes, {
          override: true,
        });

        next({
          ...to,
          replace: true,
        });
      } else if (hasPermission(store.getters.roles, to.meta.roles)) {
        store.dispatch('GenerateRoutes', {
          roles: store.getters.roles,
        });
        next();
      } else {
        next({
          path: '/error/404',
          replace: true,
          /* query: {
            noGoBack: true,
          }, */
        });
      }
    } else if (whiteList.includes(to.path)) {
      next();
    } else {
      next(`/singin?redirect=${to.path}`);
    }
  } catch (err) {
    console.warn('[router.beforeEach]', err);
  }

  NProgress.done();
});

router.afterEach(async () => {
  NProgress.done();
});
