import request from '@/api/utils';

export function getSantralList(formdata = {}, method = 'post') {
  const url = '/api/santral/list';
  return request.Api(url, formdata, method);
}

export function getSantralStatsData(formdata = {}, method = 'post') {
  const url = '/api/santraller/stats';
  return request.Api(url, formdata, method);
}
export function getSantralLastpowerData(formdata = {}, method = 'post') {
  const url = '/api/santraller/lastpower';
  return request.Api(url, formdata, method);
}

export function getSantralMinute(formdata = {}, method = 'post') {
  const url = '/api/santral/minuterpower';
  return request.Api(url, formdata, method);
}

export function getSantralHourly(formdata = {}, method = 'post') {
  const url = '/api/santral/hourpower';
  return request.Api(url, formdata, method);
}

export function getSantralDaily(formdata = {}, method = 'post') {
  const url = '/api/santral/dailypower';
  return request.Api(url, formdata, method);
}

export function getSantralMonthly(formdata = {}, method = 'post') {
  const url = '/api/santral/monthlypower';
  return request.Api(url, formdata, method);
}

export function getSantralYearly(formdata = {}, method = 'post') {
  const url = '/api/santral/yearlypower';
  return request.Api(url, formdata, method);
}
export function getSantralTotal(formdata = {}, method = 'post') {
  const url = '/api/santral/totalpower';
  return request.Api(url, formdata, method);
}

export function setSantralCreate(formdata = {}, method = 'post') {
  const url = '/api/santral/create';
  return request.Api(url, formdata, method);
}

export function setSantralEdit(formdata = {}, method = 'post') {
  const url = '/api/santral/edit';
  return request.Api(url, formdata, method);
}
 
export function getSantraLastData(formdata = {}, method = 'post') {
  const url = '/api/santral/lastpower';
  return request.Api(url, formdata, method);
}