const googleMaps = {
  state: {

  },
  getters: {

  },
  mutations: {

  },
  actions: {

  },
};

export default googleMaps;
