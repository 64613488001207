export default {
  search: 'Ara',
  Active: 'Aktif',
  Passive: 'Pasif',
  DateRange: 'Tarih Aralığı',
  Group: 'Grup',
  TariffCode: 'Tarife kodu',
  InstalledPower: 'Kurulu güç',
  Accord: 'Anlaşma',
  Adress: 'Adres',
  date: 'Tarih',
  dimensions: {

  },
  measures: {

  },
  table: {
    name: 'İsim',
  },

};
