import { getAlarmList, setAlarm } from "@/api/Request/alarm";
import helper from "@/api/helper";

const alarm = {
  state: {
    alarmlist: {},
  },
  getters: {
    alarmlist: (state) => state.alarmlist,
  },
  mutations: {
    SET_ALARM: (state, payload) => {
      state.alarmlist = payload;
    },
  },
  actions: {
    getAlarmList({ state, commit }, payload) {

      getAlarmList(payload).then((res) => {

        if (helper.resIsEmpty(res)) {

          commit('SET_ALARM', res.data.data)
        }

      });
    },
    setSelectedAlarm({ state }, payload) {
      setAlarm(payload).then((res) => {
        console.log(res)
      })
    },



  },
};

export default alarm;
