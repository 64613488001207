import store from '@/store';

function isObjectKeysExist(obj = {}, key) {
  if (typeof obj === 'object') {
    if (key in obj) {
      return true;
    }
  }
  return false;
}

function kwhToMwh(value) {
  if (!is_Empty(value)) {
    return parseFloat(value) * 0.001;
  }
  return value;
}

function resIsEmpty(res) {
  if (res.data.success == 'true') {
    if ('data' in res.data) {
 
      if (res.data.data.length > 0) {
        return true;
      }
    }
  }
  return false;
}

function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

  return JSON.parse(jsonPayload);
}

// eslint-disable-next-line camelcase
function is_Empty(data) {
  if (typeof data === 'object') {
    if (JSON.stringify(data) === '{}' || JSON.stringify(data) === '[]') {
      return true;
    }
    if (!data) {
      return true;
    }
    return false;
  }
  if (typeof data === 'string') {
    if (!data.trim()) {
      return true;
    }
    return false;
  }
  if (typeof data === 'undefined') {
    return true;
  }
  return false;
}

function isEmpty(obj) {
  // eslint-disable-next-line no-restricted-syntax
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return true;
}

function reverseObj(object) {
  const NewObj = {};
  const keysArr = Object.keys(object);
  for (let i = keysArr.length - 1; i >= 0; i--) {
    NewObj[keysArr[i]] = object[keysArr[i]];
  }
  return NewObj;
}

function setDailyData(y, m, d) {
  // eslint-disable-next-line radix
  m = parseInt(m);
  // eslint-disable-next-line radix
  d = parseInt(d);

  let month;
  let
    day;
  m < 10 ? month = `0${m}` : month = `${m}`;
  // eslint-disable-next-line no-unused-expressions
  d < 10 ? day = `0${d}` : day = `${d}`;

  return `${y}-${month}-${day}`;
}

function getUserStep() {
  const {
    santralList,
  } = store.getters;

  let stepTime = 5;
  Object.keys(santralList).forEach((k) => {
    if ('timeInterval' in santralList[k]) {
      stepTime = santralList[k].timeInterval;
    }
  });

  return stepTime;
}

function getSantralStep(santral) {
  const {
    santralList,
  } = store.getters;

  if (santral in santralList) {
    if ('santral' in santralList[santral]) {
      // eslint-disable-next-line valid-typeof
      if (typeof santralList[santral].timeInterval === 'integer') {
        return santralList[santral].timeInterval;
      }
    }
  }
  return 5;
}

function getFarmStep(prefix) {
  const {
    farmData,
  } = store.getters;

  // eslint-disable-next-line no-unreachable
  let timeInterval = 10;
  Object.keys(farmData).forEach((i) => {
    if (prefix === farmData[i].prefix) {
      timeInterval = farmData[i].timeInterval;
    }
  });

  // eslint-disable-next-line radix
  return parseInt(timeInterval);
}

const monthWeek = (s) => {
  const [y, m, d] = s.split('-');
  const date = new Date(y, m - 1, d);
  date.setDate(d - ((date.getDay() + 6) % 7));
  return Math.ceil(date.getDate() / 7);
};

const objToString = Object.prototype.toString;

// eslint-disable-next-line no-unused-vars
function isArray(value) {
  return objToString.call(value) === '[object Array]';
}

function isFunction(value) {
  return typeof value === 'function';
}

function isString(value) {
  return typeof value === 'string';
}

function isStringSafe(value) {
  return objToString.call(value) === '[object String]';
}

function isNumber(value) {
  return typeof value === 'number';
}

function isObject(value) {
  const type = typeof value;
  return type === 'function' || (!!value && type === 'object');
}
function financial(x) {
  return Number.parseFloat(x).toFixed(2);
}
function toFixed(num, fixed) {
  fixed = fixed || 0;
  fixed = Math.pow(10, fixed);
  return Math.floor(num * fixed) / fixed;
}
function toTrunc(value, n) {
  x = (`${value.toString()}.0`).split('.');
  return parseFloat(`${x[0]}.${x[1].substr(0, n)}`);
}
function setFixed(val, f = 1) {
  if (val === undefined) {
    // eslint-disable-next-line no-param-reassign
    val = 0;
  } else if (val === null) {
    // eslint-disable-next-line no-param-reassign
    val = 0;
  }
  let news;
  if (isNumber(val)) {
    news = val.toFixed(f);
  } else {
    news = parseFloat(val).toFixed(f);
  }
  return parseFloat(news);
}

function show_float_val(val, upto = 2) {
  var val = parseFloat(val);
  return parseFloat(val.toFixed(upto));
}
function NumberFormat(num, n) {
  var num = (arguments[0] != null) ? arguments[0] : 0;
  var n = (arguments[1] != null) ? arguments[1] : 2;
  if (num > 0) {
    num = String(num);
    if (num.indexOf('.') !== -1) {
      const numarr = num.split('.');
      if (numarr.length > 1) {
        if (n > 0) {
          let temp = `${numarr[0]}.`;
          for (let i = 0; i < n; i++) {
            if (i < numarr[1].length) {
              temp += numarr[1].charAt(i);
            }
          }
          num = Number(temp);
        }
      }
    }
  }
  return Number(num);
}

function myFunction(str) {
  str = str.toString().split('.');
  return str[1].slice(0, 2);
}

function truncFixed(num, to = 0) {
  const strNum = (typeof num === 'string') ? num : num.toString();
  let result = '';
  let i = 0; let
    j = -Infinity;

  for (; i < strNum.length; i++) {
    if (strNum[i] != '.') {
      result += strNum[i];
    } else {
      result += (to == 0) ? '' : strNum[i];
      j = i;
    }

    if (i == (j + to)) break;
  }

  // if you don't need padding remove this if block (all lines below this except return statement)
  if (i == strNum.length) { // for loop above terminates without break, means need pad
    if (j == -Infinity) { // decimal point not found
      if (to != 0) {
        result += '.';
        for (let k = 0; k < to; k++) {
          result += '0';
        }
      }
    } else { // add pad to the rest
      while (i <= (j + to)) {
        result += '0';
        i++;
      }
    }
  }

  return result;
}
export default {
  show_float_val,
  setFixed,
  is_Empty,
  isEmpty,
  setDailyData,
  getSantralStep,
  getUserStep,
  getParameterByName,
  parseJwt,
  getFarmStep,
  isObjectKeysExist,
  reverseObj,
  kwhToMwh,
  financial,
  toTrunc,
  toFixed,
  NumberFormat,
  myFunction,
  truncFixed,
  resIsEmpty,
};
