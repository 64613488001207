import settingLayout from '@/views/layout/Setting/TheLayout.vue';
import farmLayout from '@/views/layout/Farmlist/TheLayout.vue';

const settingRoute = [
  {
    path: '/mapslist',
    component: farmLayout,
    name: 'mapslist',
    children: [{
      path: '/mapslist',
      alias: '/',
      component: () => import('@/views/page/Farm/GoogleMaps.vue'),
      name: 'mapslist',
      meta: {
        title: 'Sahalar',
        icon: 'mdi-view-dashboard',
        // roles: ['admin', 'user', 'manager'],
      },
    }],
  },
  {
    path: '/farmlist',
    component: farmLayout,
    name: 'farmlist',
    children: [{
      path: '/farmlist',
      alias: '/',
      component: () => import('@/views/page/Farm/Farmslist.vue'),
      name: 'farmlist',
      meta: {
        title: 'Sahalar',
        icon: 'mdi-view-dashboard',
        // roles: ['admin', 'user', 'manager'],
      },
    }],
  },
  {
    path: '/error',
    component: farmLayout,
    name: 'farmerror',
    hidden: true,
    children: [{
      path: '/error',
      alias: '/',
      component: () => import('@/views/page/detail/Notification/FarmError.vue'),
      name: 'farmerror',
      meta: {
        title: 'Alarmlar',
        icon: 'mdi-view-dashboard',
        // roles: ['admin', 'user', 'manager'],
      },
    }],
  },
  {
    path: '/',
    component: settingLayout,
    name: 'adminsetting',
    children: [{
      path: '/admin/setting/info',
      alias: '/',
      component: () => import('@/views/page/admin/setting/Info.vue'),
      name: 'adminsettinginfo',
      meta: {
        title: 'Genel Görünüm',
        icon: 'mdi-view-module',
        // roles: ['admin', 'user', 'manager'],
      },
    }],
  },
  {
    path: '/',
    component: settingLayout,
    name: 'adminsetting',
    children: [{
      path: '/admin/setting/company',
      alias: '/',
      component: () => import('@/views/page/admin/setting/Company.vue'),
      name: 'adminsettingcompany',
      meta: {
        title: 'Şirket',
        icon: 'mdi-view-dashboard',
        // roles: ['admin', 'user', 'manager'],
      },
    }],
  },
  {
    path: '/',
    component: settingLayout,
    name: 'adminsetting',
    children: [{
      path: '/admin/setting/users',
      alias: '/',
      component: () => import('@/views/page/admin/setting/User.vue'),
      name: 'adminsettingusers',
      meta: {
        title: 'Kullanıcılar',
        icon: 'mdi mdi-account',
        // roles: ['admin', 'user', 'manager'],
      },
    }],
  },
  {
    path: '/',
    component: settingLayout,
    name: 'adminsettingcompanysadd',
    children: [{
      path: '/admin/setting/companys/add',
      alias: '/',
      component: () => import('@/views/page/admin/setting/CompanyAdd.vue'),
      name: 'adminsettingusers',
      meta: {
        title: 'Şirket Ekle',
        icon: 'mdi-view-module ',
        // roles: ['admin', 'user', 'manager'],
      },
    }],
  },
  {
    path: '/',
    component: settingLayout,
    name: 'adminsettingcompanysadd',
    children: [{
      path: '/admin/setting/farm/add',
      alias: '/',
      component: () => import('@/views/page/admin/setting/FarmAdd.vue'),
      name: 'adminsettingfarmadd',
      meta: {
        title: 'Saha Ekle',
        icon: 'mdi-view-module ',
        // roles: ['admin', 'user', 'manager'],
      },
    }],
  },
];

export default settingRoute;
