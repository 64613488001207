import {
  asyncRoutes,
  // constantRoutes,
} from '@/router';

/**
 * Through meta.role determines whether the current user rights match
 * @param roles
 * @param route
 */
const hasPermission = (roles, route) => {
  if (route.meta && route.meta.roles) {
    return roles.some((role) => route.meta.roles.includes(role));
  }
  return true;
};

export const filteAdminRoutes = (roles, routes) => {
  const res = [];

  routes.forEach((route) => {
    const tmp = {
      ...route,
    };

    if (hasPermission(['admin'], tmp)) {
      if ('meta' in tmp) {
        if ('admin' in tmp.meta) {
          if (tmp.meta.admin === 'true') {
            if (tmp.children) {
              tmp.children = filteAdminRoutes(roles, tmp.children);
            }
            res.push(tmp);
          }
        }
      }
    }
  });
  return res;
};

export const filterAsyncRoutes = (roles, routes) => {
  const res = [];

  routes.forEach((route) => {
    const tmp = {
      ...route,
    };

    if (hasPermission(roles, tmp)) {
      if ('meta' in tmp) {
        if (tmp.meta.admin !== 'true') {
          if (tmp.children) {
            tmp.children = filterAsyncRoutes(roles, tmp.children);
          }
          res.push(tmp);
        }
      } else {
        if (tmp.children) {
          tmp.children = filterAsyncRoutes(roles, tmp.children);
        }
        res.push(tmp);
      }
    }
  });
  return res;
};

/**
 * Recursively filter asynchronous routing tables to return routing tables
 * that meet user role permissions
 * @param roles
 * @param routes asyncRoutes
 */
export const filterAsyncRoutess = (roles, routes) => {
  const res = [];

  routes.forEach((route) => {
    const tmp = {
      ...route,
    };

    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(roles, tmp.children);
      }
      res.push(tmp);
    }
  });
  return res;
};

const storage = window.localStorage;

const permission = {
  state: {
    routes: [],
    addRoutes: [],
    adminRoute: 'true',
  },

  getters: {
    permissionRoutes: (state) => state.routes,
    addRoutes: (state) => state.addRoutes,
    adminRoute: (state) => state.adminRoute,
  },

  mutations: {
    SET_ROUTES: (state, routes) => {
      //  state.addRoutes = routes;
      // state.routes = constantRoutes.concat(routes);
    },
    SET_ADMIN_ROOT: (state, payload) => {
      state.adminRoute = payload;
    },
  },
  actions: {
    async setAdminRoute({
      commit,
    }, {
      payload,
    }) {
      commit('SET_ADMIN_ROOT', payload);
    },
    async GenerateRoutes({
      commit,
    }, {
      roles,
    }) {
      let accessedRoutes;
      // eslint-disable-next-line no-shadow
      // const adminRoute = storage.getItem('adminRoute');

      try {
        /*  if (adminRoute === 'true') {
           accessedRoutes = filteAdminRoutes(roles, constantRoutes.concat(asyncRoutes));
         } else {
           accessedRoutes = filterAsyncRoutes(roles, asyncRoutes);
         }

         commit('SET_ROUTES', accessedRoutes); */
      } catch (err) {
        console.warn('[vuex.permission] GenerateRoutes', err);
      }

      return accessedRoutes;
    },
  },
};

export default permission;
