import request from '@/api/utils';

export function getStringboxDataList(formdata = {}, method = 'post') {
  const url = '/api/stringbox/list';
  return request.Api(url, formdata, method);
}



export function stringboxMinute(formdata = {}, method = 'post') {
  const url = '/api/stringbox/minutecomparison';
  return request.Api(url, formdata, method);
}


export function stringboxHourly(formdata = {}, method = 'post') {
  const url = '/api/stringbox/hourcomparison';
  return request.Api(url, formdata, method);
}


export function stringboxDaily(formdata = {}, method = 'post') {
  const url = '/api/stringbox/daily';
  return request.Api(url, formdata, method);
}

export function stringboxMonthly(formdata = {}, method = 'post') {
  const url = '/api/stringbox/monthly';
  return request.Api(url, formdata, method);
}

export function stringboxYearly(formdata = {}, method = 'post') {
  const url = '/api/stringbox/yearly';
  return request.Api(url, formdata, method);
}

export function getStringboxList(formdata = {}, method = 'post') {
  const url = '/api/stringbox/getStringboxList';
  return request.Api(url, formdata, method);
}
export function setStringboxDelete(formdata = {}, method = 'post') {
  const url = '/api/stringbox/setStringboxDelete';
  return request.Api(url, formdata, method);
}

export function setStringbox(formdata = {}, method = 'post') {
  const url = '/api/stringbox/setStringbox';
  return request.Api(url, formdata, method);
}