// eslint-disable-next-line import/no-cycle
import request from '@/api/utils';

const saleData = {
  state: {
    dashList: {},
  },
  getters: {
    dashList: (state) => state.dashList,
  },
  mutations: {
    SET_DASH_LIST: (state, payload) => {
      state.dashList[payload.reportCode] = payload.result;
    },
  },
  actions: {
    getSaleYearlyData: async ({ commit }, payload) => {
      try {
        const rest = request.post(payload.funcName, payload.query);
        rest.then((res) => {
          const result = JSON.parse(JSON.stringify(res.data.data));
          if (res.status !== 200) return;
          if (res.data.success === true) {
            const obj = {};
            obj.result = result;
            obj.reportCode = payload.reportCode;
            commit('SET_DASH_LIST', obj);
          }
        });
      } catch (err) {
        console.warn('[vuex.user] GetCubeList', err);
      }
    },
  },
};

export default saleData;
