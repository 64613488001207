import detailLayout from '@/views/layout/Detail/TheLayout.vue';
import farmLayout from '@/views/layout/Farmlist/TheLayout.vue';

const detailRoute = [{
  path: '/mapslist',
  component: farmLayout,
  name: 'mapslist',
  children: [{
    path: '/mapslist',
    alias: '/',
    component: () => import('@/views/page/Farm/GoogleMaps.vue'),
    name: 'mapslist',
    meta: {
      title: 'Harita',
      icon: 'mdi-view-dashboard',
      // roles: ['admin', 'user', 'manager'],
    },
  }],
},
{
  path: '/farmlist',
  component: farmLayout,
  name: 'farmlist',
  children: [{
    path: '/farmlist',
    alias: '/',
    component: () => import('@/views/page/Farm/Farmslist.vue'),
    name: 'farmlist',
    meta: {
      title: 'Sahalar',
      icon: 'mdi-view-dashboard',
      // roles: ['admin', 'user', 'manager'],
    },
  }],
},
{
  path: '/error',
  component: farmLayout,
  name: 'farmerror',
  hidden: true,
  children: [{
    path: '/error',
    alias: '/',
    component: () => import('@/views/page/detail/Notification/FarmError.vue'),
    name: 'farmerror',
    meta: {
      title: 'Alarmlar',
      icon: 'mdi-view-dashboard',
      // roles: ['admin', 'user', 'manager'],
    },
  }],
},
{
  path: '/dashboard',
  component: detailLayout,
  name: 'dashboard',
  children: [{
    path: '/dashboard',
    alias: '/',
    component: () => import('@/views/page/detail/Dashboard/Index.vue'),
    name: 'dashboard',
    meta: {
      title: 'route.dashboard',
      icon: 'mdi-view-dashboard',
      // roles: ['admin', 'user', 'manager'],
    },
  }],
},
{
  path: '/sensorbox',
  component: detailLayout,
  name: 'Sensorbox',

  children: [{
    path: '/sensorbox',
    alias: '/',
    component: () => import('@/views/page/detail/Sensorbox/Index.vue'),
    name: 'Sensorbox',
    title: 'route.Sensorbox',
    meta: {
      title: 'route.Sensorbox',
      icon: 'mdi-view-module',
      affix: true,
      // roles: ['admin', 'user', 'manager'],
    },
  }],
},
{
  path: '/osos',
  component: detailLayout,
  name: 'Osos',
  meta: {
    title: 'Osos',
    icon: 'mdi-view-dashboard',
  },
  children: [{
    path: '/osos/home',
    alias: '/',
    component: () => import('@/views/page/detail/Osos/Home.vue'),
    name: 'Ososhome',
    meta: {
      title: 'Genel Bakış',
      icon: 'mdi-view-module',
      affix: true,
      // roles: ['admin', 'user', 'manager'],
    },
  },
  {
    path: '/osos/endex',
    alias: '/',
    component: () => import('@/views/page/detail/Osos/Endex.vue'),
    name: 'Ososendeks',
    meta: {
      title: 'Endeks',
      icon: 'mdi-view-module',
      affix: true,
      // roles: ['admin', 'user', 'manager'],
    },
  }, {
    path: '/osos/consumption',
    alias: '/',
    component: () => import('@/views/page/detail/Osos/Consumption.vue'),
    name: 'Ososconsumption',
    meta: {
      title: 'Tüketim',
      icon: 'mdi-view-module',
      affix: true,
      // roles: ['admin', 'user', 'manager'],
    },
  },
  {
    path: '/osos/cvc',
    alias: '/',
    component: () => import('@/views/page/detail/Osos/Current.vue'),
    name: 'Akım',
    meta: {
      title: 'Akım',
      icon: 'mdi-view-module',
      affix: true,
      // roles: ['admin', 'user', 'manager'],
    },
  },
  ],
},
{
  path: '/',
  component: detailLayout,
  name: 'santral',
  meta: {
    title: 'Santral',
    icon: 'mdi-view-dashboard',
  },
  children: [{
    path: '/santral/analysis',
    alias: '/',
    component: () => import('@/views/page/detail/Santral/Analysis.vue'),
    name: 'santralanalysis',
    meta: {
      title: 'Analiz',
    },
  },
  {
    path: '/santral/heatmapcomp',
    alias: '/',
    component: () => import('@/views/page/detail/Santral/Heatmapcomp.vue'),
    name: 'santralheatmapcomp',
    meta: {
      title: 'Karşılaştır',
    },
  },
  {
    path: '/santral/heatmap',
    alias: '/',
    component: () => import('@/views/page/detail/Santral/Heatmap.vue'),
    name: 'santralheatmap',
    meta: {
      title: 'Heatmap',
    },
  },
  ],
},
{
  path: '/',
  component: detailLayout,
  name: 'notification',
  meta: {
    title: 'Alarm',
    icon: 'mdi-bell',
  },
  children: [{
    path: '/notification/error',
    alias: '/',
    component: () => import('@/views/page/detail/Notification/Error.vue'),
    name: 'notificationerror',
    meta: {
      title: 'Hata',
    },
  },
  /*  {
         path: '/notification/warning',
         alias: '/',
         component: () => import('@/views/page/detail/Notification/Warning.vue'),
         name: 'notificationwarning',
         meta: {
           title: 'Uyarı',
         },
       }, */
  {
    path: '/notification/device',
    alias: '/',
    component: () => import('@/views/page/detail/Notification/Device.vue'),
    name: 'notificationdevice',
    meta: {
      title: 'Cihaz',
    },
  },
  ],
},
{
  path: '/',
  component: detailLayout,
  name: 'inverter',
  meta: {
    title: 'Inverter',
    icon: 'mdi mdi-stove',
  },
  children: [{
    path: '/inverter/farmanalysis',
    alias: '/',
    component: () => import('@/views/page/detail/Inverter/FarmAnalysis.vue'),
    name: 'inverterfarmheatmap',
    meta: {
      title: 'Saha Karşılaştır',
    },
  },
  {
    path: '/inverter/heatmap',
    alias: '/',
    component: () => import('@/views/page/detail/Inverter/Heatmap.vue'),
    name: 'inverterheatmap',
    meta: {
      title: 'Karşılaştır',
    },
  },
  {
    path: '/inverter/status',
    alias: '/',
    component: () => import('@/views/page/detail/Inverter/Status.vue'),
    name: 'inverterstatus',
    meta: {
      title: 'Durum',
    },
  },
  {
    path: '/inverter/live',
    alias: '/',
    component: () => import('@/views/page/detail/Inverter/Live.vue'),
    name: 'inverterlive',
    meta: {
      title: 'Anlık',
    },
  },
  {
    path: '/inverter/init',
    alias: '/',
    component: () => import('@/views/page/detail/Inverter/Index.vue'),
    name: 'inverterinit',
    meta: {
      title: 'İnverter',
    },
  },
  {
    path: '/inverter/analysis',
    alias: '/',
    component: () => import('@/views/page/detail/Inverter/Analysis.vue'),
    name: 'inverteranalysis',
    meta: {
      title: 'Analiz',
    },
  },
  ],
},
{
  path: '/',
  component: detailLayout,
  name: 'stringbox',
  meta: {
    title: 'Stringbox',
    icon: 'mdi-cube-outline',
  },
  children: [{
    path: '/stringbox/analiz',
    alias: '/',
    component: () => import('@/views/page/detail/StringBox/FarmAnalysis.vue'),
    name: 'stringboxanalys',
    meta: {
      title: 'Stringbox Analiz',
    },
  },
  {
    path: '/stringbox/comparison',
    alias: '/',
    component: () => import('@/views/page/detail/StringBox/Comparison.vue'),
    name: 'stringboxcomparison',
    meta: {
      title: 'String Karşılaştırma',
    },
  },
  {
    path: '/stringbox/heatmap',
    alias: '/',
    component: () => import('@/views/page/detail/StringBox/Heatmap.vue'),
    name: 'stringboxheatmap',
    meta: {
      title: 'Heatmap',
    },
  },
  {
    path: '/stringbox/heatmapcomp',
    alias: '/',
    component: () => import('@/views/page/detail/StringBox/HeatmapComparison.vue'),
    name: 'stringboxheatmapcomp',
    meta: {
      title: 'Stringler',
    },
  },
  {
    path: '/stringbox/string',
    alias: '/',
    component: () => import('@/views/page/detail/StringBox/Minute.vue'),
    name: 'stringboxstring',
    meta: {
      title: 'route.String',
    },
  },
  {
    path: '/stringbox/analysis',
    alias: '/',
    component: () => import('@/views/page/detail/StringBox/TimeAnalysis.vue'),
    name: 'stringboxanalysis',
    meta: {
      title: 'route.Analysis',
    },
  },
  ],
},

{
  path: '/',
  component: detailLayout,
  name: 'analysis',
  meta: {
    title: 'route.Analysis',
    icon: 'mdi-cube-outline',
  },
  children: [{
    path: '/analysis/stats',
    alias: '/',
    component: () => import('@/views/page/detail/Analysis/Stats.vue'),
    name: 'analysisstats',
    meta: {
      title: 'route.stats',
    },
  },
  {
    path: '/analysis/santral',
    alias: '/',
    component: () => import('@/views/page/detail/Analysis/Santral.vue'),
    name: 'analysissantral',
    meta: {
      title: 'route.Santral_Analysis',
    },
  },
  {
    path: '/analysis/date',
    alias: '/',
    component: () => import('@/views/page/detail/Analysis/DateAnalysis.vue'),
    name: 'analysisdate',
    meta: {
      title: 'route.DateAnalysis',
    },
  },
  {
    path: '/analysis/comparison',
    alias: '/',
    component: () => import('@/views/page/detail/Analysis/Comparison.vue'),
    name: 'analysiscomparison',
    meta: {
      title: 'route.Comparison',
    },
  },
  ],
},
];

export default detailRoute;
