import 'normalize.css/normalize.css';
import 'vuetify/dist/vuetify.min.css';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import 'vue-pivottable/dist/vue-pivottable.css';

import Vue from 'vue';
import Vuelidate from 'vuelidate';
import ExportDocument from '@/views/components/Shared/ExportDocument.vue';
import vuetify from './plugins/vuetify';
import i18n from './locale';
import API from './api';
import App from './App.vue';
import router from './router';
import store from './store';

import './permission';
// import './registerServiceWorker';

Vue.config.productionTip = false;
Vue.prototype.$api = API;
Vue.use(Vuelidate);
Vue.component('ExportDocument', ExportDocument);
console.log("v2")
// eslint-disable-next-line import/prefer-default-export
export const eventBus = new Vue();

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
